/** Header Styling **/

.header {
  height: 7.5vh;
  background-color: #1d1d1d;
  width: auto;
}

.header-title {
  color: white;
  text-align: center;
  text-shadow: 2px 2px black;
  font-size: x-large;
  padding: 10px;
  font-weight: bold;
}

@media only screen and (max-width: 768px) {
  /* For even smaller mobile phones: */

  [class*="header-title"] {
    font-size: large;
  }
}
