/** Restaurant Component Styling **/

.restaurant {
  height: 100%;
  width: auto;
  padding-left: 10vw;
  padding-right: 10vw;
  padding-top: 15px;
}

.restaurant-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.welcome {
  font-weight: bold;
  text-align: center;
  font-size: xx-large;
}

@media only screen and (max-width: 768px) {
  /* For even smaller mobile phones: */

  [class*="welcome"] {
    font-size: large;
  }
}
