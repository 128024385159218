/**Restaurant Item Styling **/

.restaurant-item {
  background-color: white;
  margin: 30px;
  min-height: 128px;
  height: auto;
  max-width: 200px;
  box-shadow: 0 0 3px black;
  border-radius: 4px;
  display: flex;
  flex: 1;
  transition: ease-in 0.1s;
  flex-direction: column;
  cursor: pointer;
}
.restaurant-item:active {
  background-color: #c1c1c1;
}

.image-container {
  min-width: 128px;
  flex: 1;
}

.restaurant-info {
  flex: 2;
  text-align: left;
  padding: 10px;
}

.restaurant-name {
  font-size: large;
  font-weight: bold;
}

.restaurant-address {
  font-size: medium;
}
.open-container {
  flex: 0.5;
  margin: 5px;
  text-align: center;
  font-size: smaller;
  font-weight: bold;
  width: auto;
}

.closed {
  padding: 5%;
  text-align: center;
  color: #ffffff;
  box-shadow: 0px 1px 2px #00000029;
  background: #ff383b 0% 0% no-repeat padding-box;
  border-radius: 4px;
}
.open {
  padding: 5%;
  text-align: center;
  color: #ffffff;
  box-shadow: 0px 1px 2px #00000029;
  background: #4caf50 0% 0% no-repeat padding-box;
  border-radius: 4px;
}

.icon {
  text-align: center;
}

/**Mobile or small devices Styling **/

@media only screen and (max-width: 666px) {
  /* For mobile phones: */
  [class*="restaurant-item"] {
    flex-direction: row;
    max-width: unset;
    width: auto;
    margin: unset;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  [class*="image-container"] {
    height: auto;
    width: 100%;
  }
  [class*="icon"] {
    padding-right: 5px;
  }
  [class*="restaurant-name"] {
    font-size: medium;
  }
  [class*="restaurant-address"] {
    font-size: small;
  }
  [class*="open-container"] {
    font-weight: unset;
  }
}

@media only screen and (min-width: 768px) {
  .restaurant-item:hover {
    transform: scale(1.1);
  }
}

@media only screen and (max-width: 352px) {
  /* For even smaller mobile phones: */

  [class*="restaurant-name"] {
    font-size: small;
  }
  [class*="restaurant-address"] {
    font-size: x-small;
  }
  [class*="open-container"] {
    font-size: xx-small;
  }
}
