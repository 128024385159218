/** Category Styling **/

.category-box {
  display: flex;
  height: auto;
  flex-direction: row;
  padding: 10px;
  border-bottom: black;
  border-bottom: 1px solid black;
  cursor: pointer;
}

.category-box:hover {
  background-color: #f1f1f1;
}
.category-box:active {
  background-color: #c1c1c1;
}

.category-title {
  flex: 9;
  font-size: x-large;
}
.category-icon {
  text-align: center;
  flex: 1;
  align-items: flex-end;
  padding-top: 10px;
}

.category-list {
  flex-wrap: wrap;
  display: flex;
}
