/** Menu Styling **/

.menu {
  height: 100%;
  width: auto;
  padding-left: 15vw;
  padding-right: 15vw;
}

.backbutton {
  margin: 15px;
  padding: 5px;
  background-color: #f1f1f1;
  width: 64px;
  border-radius: 50px;
  transition: ease-in 0.1s;
  cursor: pointer;
  box-shadow: 0 0 3px black;
}

.backbutton:hover {
  transform: scale(1.1);
}
.backbutton:active {
  background-color: #c1c1c1;
}

.info-container {
  flex-direction: row;
  display: flex;
  width: auto;
  background-color: #f4f4f4;
  border-radius: 4px;
  box-shadow: 0 0 3px black;
  margin-bottom: 20px;
}

.info-image {
  height: 20%;
  width: 20%;
}

.info-text {
  text-align: left;
  flex: 1;
}

.restaurante-name {
  font-size: x-large;
  font-weight: bold;
}

.infos {
  padding: 5px;
  flex: 8;
}

.icon {
  padding-right: 5px;
}

.right-container {
  background-color: gray;
  width: auto;
  height: 100%;
}

.flex-row {
  display: flex;
  flex-direction: row;
}

.hours {
  padding-left: 5px;
  font-weight: bold;
  font-size: medium;
}

.days {
  font-size: medium;
}

.filtered-list {
  flex-wrap: wrap;
  display: flex;
}

.spinner {
  text-align: center;
}

/**Mobile or small devices Styling **/

@media only screen and (max-width: 768px) {
  /* For mobile phones: */
  [class*="info-container"] {
    flex-direction: column;
  }
  [class*="info-image"] {
    height: 100%;
    width: 100%;
  }
}
