.App {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  height: 100%;
  width: auto;
  background-color: #fbfbfd;
}

.body {
  min-height: 90vh;
  height: auto;
  background-color: #fbfbfd;
}
