/** FoodItem Styling **/

.item-container {
  background-color: white;
  margin: 20px;
  height: auto;
  width: 100%;
  min-height: 128px;
  max-width: 400px;
  box-shadow: 0 0 5px black;
  border-radius: 4px;
  display: flex;
  transition: ease-in 0.1s;
  flex-direction: row;
  cursor: pointer;
}

.item-container:active {
  background-color: #c1c1c1;
}

.modal-image {
  object-fit: cover; /* Do not scale the image */
  object-position: center; /* Center the image within the element */
  overflow: hidden;
  max-height: 511px;
  height: auto;
}

.food-info {
  flex: 3;
  display: flex;
  flex-direction: column;
  padding: 5px;
}

.image-container {
  flex: 1;
  height: 128px;
}

.food-name {
  font-size: medium;
  font-weight: bold;
}

.food-description {
  font-size: small;
  color: gray;
}

.food-price-info {
  padding: 10px;
  display: flex;
  flex-direction: row;
}

.food-price {
  color: #009ca3;
  font-size: large;
  font-weight: bold;
}

.food-discount {
  font-size: medium;
  color: gray;
  text-decoration: line-through;
  padding-left: 10px;
  text-align: center;
  vertical-align: auto;
}

/**Mobile or small devices Styling **/

@media only screen and (max-width: 768px) {
  /* For mobile phones: */
  [class*="item-container"] {
    flex-direction: column;
    max-height: none;
  }
  [class*="image-container"] {
    height: auto;
    width: 100%;
  }
  [class*="modal-image"] {
    max-height: 256px;
    width: 100%;
  }
}

@media only screen and (min-width: 768px) {
  .item-container:hover {
    transform: scale(1.1);
  }
}
